.tile {
    width: 50px;
    height: 50px;
    margin: auto;
    background-color: #333;
    color: #fff;
    font-weight: bold;
    text-align: center;
    line-height: 40px;
    position: relative;
    /* -webkit-filter: drop-shadow(3px 3px 3px #ffffffad); */
    border: 2px solid transparent; /* Placeholder to avoid shift */
    box-shadow: 0 0 5px rgba(0, 255, 255, 0.7), 0 0 10px rgba(0, 255, 255, 0.5),
      0 0 15px rgba(0, 255, 255, 0.3);
    animation: neonRotate 3s linear infinite;
    transition: background-color 0.5s ease;
    
  }
  
  @keyframes neonRotate {
    0% {
      box-shadow: 0 0 10px rgba(0, 255, 255, 0.7), 0 0 20px rgba(0, 255, 255, 0.5),
        0 0 30px rgba(0, 255, 255, 0.3);
      border: 2px solid rgba(0, 255, 255, 0.6);
    }
    50% {
      box-shadow: 0 0 20px rgba(0, 255, 255, 0.6), 0 0 20px rgba(0, 255, 255, 0.6),
        0 0 40px rgba(0, 255, 255, 0.4);
      border: 2px solid rgba(0, 255, 255, 0.8);
    }
    100% {
      box-shadow: 0 0 10px rgba(0, 255, 255, 0.4), 0 0 20px rgba(0, 255, 255, 0.5),
        0 0 30px rgba(0, 255, 255, 0.3);
      border: 2px solid rgba(0, 255, 255, 0.6);
    }
  }

  .minesweeper-game{
    width: 50vw;
    margin: auto;
  }
  