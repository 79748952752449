.foot{
    background-color: transparent !important;
    position:fixed;
    left:0;
    bottom:0;
    right:0;
    font-size: 20px;
}

@media screen and (max-width: 500px) {
    .foot-text {
      font-size: 10px;
      bottom:0;
    }

    .foot{
        size: 2px;
    }
  }