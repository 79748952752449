/* Default styles */
.success-message-container {
    position: absolute;
    margin-top: 20rem;
    top: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
    z-index: 1000; /* Ensure the message appears on top of the canvas */
    transform: scale(0); /* Initially scale down the message */
    transform: opacity(0);
    animation: fadeInAndScaleUp 0.5s ease-in forwards;
  }
  
  .success-message {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 20px;
    border-radius: 10px;
    text-align: center;
  }
   /* Media query for smaller screens */
   @media screen and (max-width: 1100px) {
    .success-message-container {
      margin-top: 10rem; /* Adjust margin for smaller screens */
      scale: 1;
    }
  
    .success-message {
      padding: 0; /* Adjust padding for smaller screens */
    }
  }

  
  /* Media query for smaller screens */
  @media screen and (max-width: 768px) {
    .success-message-container {
      margin-top: 3rem; /* Adjust margin for smaller screens */
      scale : 0.5;
    }
  
    .success-message {
      padding: 0; /* Adjust padding for smaller screens */
    }
  }


  
  @keyframes fadeInAndScaleUp {
    0% {
      opacity: 0;
      transform: scale(0);
    }
    50% {
      opacity: 0.5; /* Optional: midway opacity */
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  