

.dots-div {
}

.dots-div::before {
  content: ' ';
  position: absolute;
  top: -8rem;
  margin-left: -50vw;
  width: 100dvw;
  height: 100dvh;
  /* background: url('../images/dots2.png') center center; */
  background-repeat: no-repeat;
  background-size: contain;
  pointer-events: none;
  z-index: 0;
  animation: rotateBackground 40s linear infinite; /* Adjust the duration and timing function as needed */
}

.content {
  z-index: 10; /* Ensure content is above the rotating background */
}

@keyframes rotateBackground {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.my-box2 {
  min-width: 40vw;
  border-radius: 1rem;
  background-color: rgba(255, 255, 255, 0.2);
  opacity: 1;
  position: absolute;
  overflow: hidden;
  z-index: 10;
  transform: translateX(200vw);
  transition: transform 0.5s ease, opacity 0.5s ease;
}

@media screen and (max-width: 575px) {
  .my-box2 {
    min-width: 90vw /* Adjust the font size for smaller screens */
  }
}

@media screen and (max-width: 800px) {
  .entry{
    font-size: 16px !important;
  }
}

.come {
  transform: translateX(0vw); /* Adjust the scale factor as needed */
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Adjust the minmax value as needed */
  grid-gap: 16px; /* Adjust the gap between items as needed */
}

.typewriter-text{
  display: flex;
  font-family: 'Courier New', monospace;
  font-size: 24px;
  overflow: hidden;
  white-space: nowrap;
  border-right: .15em solid #ffffff; 
  animation: typewriter-anim 2s steps(40) 0.3s;
}

.webOption{
  background: rgba(255,255,255,0.4)!important;
  border-radius: 10px;
  transition: all 0.1s ease;
}

@keyframes typewriter-anim {
  from {
    width: 0;
  }
  to {
    width: 100%
  }
}

a{
  color: inherit !important;
  text-decoration: none !important;
}

.entry{
  margin: 1rem !important;
}

.portfolio-btn{
  background-color: rgba(255, 255, 255, 0.2);
}

.glitchy-text {
  color: rgb(148, 255, 175);
  position: relative;
  overflow: hidden;
}

.glitchy-text::before,
.glitchy-text::after {
  color: #0f0; 
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.glitchy-text::before {
  color: rgba(148, 212, 255,0.6); /* Set the color for the glitch effect */
  clip-path: polygon(0 0%, 100% 0, 100% 85%, 0.2rem 70%);
  z-index: 2;
  animation: glitch 1s linear infinite;
}

.glitchy-text::after {
  color: #0f0; /* Set the color for the glitch effect */
  clip-path: polygon(0 0, 100% 100%, 100% 100%, 0 120%);
  z-index: -1;
  animation: glitch 1s linear infinite;
}

@keyframes glitch {
  10%, 64% {
    transform: translate(1px, 0);
  }
  4%, 60% {
    transform: translate(-0.3px, 0);
  }
  62% {
    transform: translate(0, 0);
  }
}
