.circle-container {
    width: 80vw; /* Set width as a percentage of viewport width */
    max-width: 200px; /* Set maximum width */
    height: 80vw; /* Set height same as width for a circular shape */
    max-height: 200px; /* Set maximum height */
    border-radius: 50%;
    background-color: #191553a9;
    margin: auto;
    position: relative;
    text-align: center;
    line-height: auto;
    font-size: 1.5rem; /* Adjust font size */
  }

  .circle-container div {
    position: absolute;
    width: 40px;
    height: 40px;
    background-color: #000000;
    color: #fff348;
    border: none;
    border-radius: 50%;
    font-size: 14px;
    cursor: pointer;
  }

  .up {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .down {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .left {
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  .right {
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }


  .gravity-text{
    margin-top: 1rem !important;
  }

  .game-container{
    width: 100dvw;
    height: 100dvh;
  }