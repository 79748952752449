.my-body{
    height: 100vh;
    background-color: transparent;
    opacity: 1; /* Initial opacity value */
    transition: background-color 0.5s ease, opacity 0.5s ease; /* Transition properties */
}

.profile-picture{
  rotate: -3deg;
  margin-top: 0px; /* Center vertically */
  background-image: url('../images/pic_frame2.png');
  background-size: cover; /* Ensures the background image covers the entire container */
  background-position: center; /* Centers the background image within the container */
  background-repeat: no-repeat;
  overflow: visible;
  z-index: 2!important;
}

.background-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  
  .arrow {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 20px solid red;
    transform: translateX(-50%);
  }

  .my-pic{
    position: relative;
    transform: scale(0.62);
    rotate: 3deg;
    left: 2%;
    top : -3cap;
    width: 100%;
    z-index: 1;
    opacity: 1;
  }
  .my-pic::after{
    content: "";
    z-index: 2;
    opacity: 1;
    background-size: cover; /* Cover the pseudo-element */
    background-image: url('../images/hard_paper_texture.png'); /* Background image */
  }

  .paper-title {
    position: relative;
    z-index: 1000; /* Layer in front of the pseudo-element */
    font-size: 36px; /* Title font size */
    color: #333; /* Text color */
    margin-top: 10px;
    padding: 0px; /* Padding around the title */
}

.paper-title::before {
    content: ""; /* Required for pseudo-element to display */
    position: absolute;
    top: 15%; /* Center vertically */
    left: 50%; /* Center horizontally */
    width: 150%; /* Width of the pseudo-element */
    height: 230%; /* Height of the pseudo-element */
    background-size: cover;
    background-image: url('../images/cartoon_paper.png'); /* Background image */
    z-index: -1; /* Layer behind the content */
    transform: translate(-50%, -50%) rotate(0deg); /* Center and rotate the image */
    opacity: 1; /* Optional: Set opacity for a softer background */
}

.paper-text {
  position: relative;
  z-index: 0; /* Layer in front of the pseudo-element */
  color: #333; /* Text color */
  margin-top: 10px;
  padding: 10px; /* Positive padding */
  /* animation: shake 1s ease-in-out 0s forwards, shake 0.5s ease-in-out 2.5s forwards; Shake animations */
  background-size: contain;
  background-repeat: no-repeat;
  transition: scale 0.4s ease, background-size 0.4s ease-out;
}

@keyframes shake {
  0% { transform: translate(0); }
  25% { transform: translate(-5px, 0); }
  50% { transform: translate(5px, 0); }
  75% { transform: translate(-5px, 0); }
  100% { transform: translate(0); }
}

.skill-container {
    background-image: url('../images/paper3.png'); /* Background image */
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
    background-size: 100% 100%;
    padding-top: 85px;
    margin: auto!important;
    z-index: 2!important;
}

.personal-info{
  background-image: url('../images/hands_paper.png');
  width: 100%;
  height: fit-content;
  background-position: center;
  background-size: 150% 120%;
  background-repeat:no-repeat;
  font-size: medium!important;
  padding: 7rem; 
  margin: auto;
  transition: all 1s ease;
  z-index: -1!important;
}

.info-text{
  width: 60%;
  margin: auto;
}


.custom-hover{
  transition: all 1s ease;
}
.custom-hover:hover{
  -webkit-filter: drop-shadow(5px 50px 5px #222222ad);
  cursor: pointer;
  transform: scale(1.1);
}

.custom-hover2{
  transition: all 1s ease;
}
.custom-hover2:hover{
  cursor: pointer;
  -webkit-filter: drop-shadow(5px 5px 5px #222222ad);
  filter: drop-shadow(5px 50px 5px #222);
  transform: rotateX(15deg) rotateY(5deg) scale(1.01) translateY(-5px);
}

/* Extra Small Devices (Portrait Phones, less than 576px) */
@media screen and (max-width: 575.98px) {
  .personal-info {
    font-size: 14px !important;
    scale: 1;
    background-size: cover;
    padding-left: 0rem;
    padding-right: 0rem;
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
}

/* Small Devices (Landscape Phones, 576px and up) */
@media screen and (min-width: 576px) and (max-width: 767.98px) {
  .personal-info {
    font-size: 14px !important;
    scale: 1;
    background-size: cover;
    padding-left: 0rem;
    padding-right: 0rem;
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
}

/* Medium Devices (Tablets, 768px and up) */
@media screen and (min-width: 768px) and (max-width: 991.98px) {
  .personal-info {
    font-size: 7px !important;
    scale: 1.6;
    background-size: contain;
    padding: 3rem;
    margin: auto;
  }
}

/* Large Devices (Laptops, 992px and up) */
@media screen and (min-width: 992px) and (max-width: 1199.98px) {
  .personal-info {
    font-size: 10px !important;
    scale: 1.5;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    padding: 3rem;
    padding-top: 4rem;
    margin: auto;
  }
}

/* Extra Large Devices (Desktops, 1200px and up) */
@media screen and (min-width: 1200px) {
  .personal-info {
    font-size: 14px !important;
    scale: 1.3;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 5rem;
    margin: auto;
  }
}
