

  .welcome{
    z-index: 4;
  }

  .description{
    z-index: 1;
    width: 70vw;
    margin: auto!important;
    justify-self:center;

  }

  .top-side{
    z-index: 99!important;
  }


  .home-body{
    z-index: -2;
    background: transparent!important;
    background-color: transparent;
  }

  .paper-overlay{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100vw;
    z-index: 10000;
    pointer-events: none;
  }


