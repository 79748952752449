.App {
  text-align: center;
  min-height: 100dvh;
  background-position: cover;
  overflow-x: hidden;
  overflow-y: scroll;
  /* scroll-behavior: smooth; */
  /* scroll */
}

body {
  font-family: 'Roboto', sans-serif!important;
  overflow-x: hidden;
  overflow-y: hidden;
  scroll-behavior: smooth;
  margin: 0; /* Remove default body margin */
}
