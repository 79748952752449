/* styles.css */
.skill-table{
  background: transparent;
}

.skill-option{
    cursor: pointer;
    width: 80%;
    line-height: 0.6rem;
    font-size: medium;
    padding: 0.3rem;
    margin: auto;
    margin-top: 0rem!important;
    margin-bottom: 0rem!important;
    font-family: roboto;
    background: transparent;
    position: relative; /* Required for the ::before positioning */
    transition: all 1s ease;
  }

  .skill-title{
    cursor: pointer;
    width: 80%;
    line-height: 0.6rem;
    font-size: large;
    padding: 0.3rem;
    margin: auto;
    margin-top: 0rem!important;
    margin-bottom: 0rem!important;
    font-family: roboto;
    background-color: rgba(0, 0, 0, 0.445)!important;
    color: rgb(255, 255, 255)!important;
    position: relative; /* Required for the ::before positioning */
    transition: all 1s ease;
  }
  
  .skill-option::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius:4rem;
    background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) 50%, rgba(0,0,0,0) 100%);
    opacity: 0;
    transition: opacity 1s ease;
    z-index: -1; /* Ensure the background stays behind the text */
  }
  
  .skill-option.active::before {
    opacity: 1;
  }
  
  .skill-option.active {
    color: black;
    padding: auto;
    transform: scale(1);
    z-index: 1;
  }

  .typewriter {
    overflow: hidden; /* Ensures text is hidden until revealed */
    display: inline-block; /* Allows inline-block elements to be animated */
  }
  
  .typewriter span {
    display: inline-block;
    opacity: 1; /* Start with text hidden */
    animation: typewriter 1s steps(40) forwards; /* Typewriter animation */
  }

  .skill-table{
    max-lines: 3!important;
    /* max-height: 10vh!important; */
    /* overflow: scroll; */
  }
  
  @keyframes typewriter {
    from {
      width: 0; /* Start with zero width */
    }
    to {
      width: auto; /* Gradually expand width to reveal characters */
      opacity: 1; /* Fade in characters */
    }
  }
 
  