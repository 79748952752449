.navbar.bg-body-tertiary {
  background-color: transparent !important;
}

.nav-item {
  z-index: 10001 !important;
  border-radius: 0rem !important;
  padding-right: 0.3rem;
  padding-left: 0.3rem;
  transition: all 1s ease-in-out;
}

.nav-item.chosen {
  transform: scale(1.05);
}

.nav-item.chosen-inv {
  transform: scale(1.05);
}

.chosen {
  transition: transform 1s ease, top 1s ease, color 0.5s ease, background-color 0.5s ease-in-out;
  background-color: rgba(0, 0, 0, 0.2);
  top: 0rem;
}

.chosen-inv {
  transition: transform 1s ease, top 1s ease, color 0.5s ease, background-color 0.5s ease-in-out;
  background-color: rgba(255, 255, 255, 0.3);
  top: 0rem;
}

.navbar-class {
  border: 0.05rem solid white;
  position: relative; /* Ensure stacking context */
  z-index: 10000; /* Ensure navbar is on top */
}

.logo {
  width: 8rem;
  border: 0.05rem solid white;
  z-index: 10001; /* Ensure logo is on top of navbar content */
}

.overlay-container {
  position: fixed; /* Fixed position for full viewport overlay */
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1; /* Ensure it's behind all content */
  pointer-events: none; /* Allow clicks to pass through */
}

.overlay-image {
  opacity: 0.3;
  position: absolute;
  top: 0;
  left: 0;
  right:0;
  margin: auto;
  width:50%;
  height: 80%;
  background-image: url('../images/polygons2.png'); /* Adjust path as needed */
  background-size: contain;
  background-position: center;
  background-repeat:no-repeat;
  z-index: 0; /* Ensure it's behind all content */
  pointer-events: none; /* Allow clicks to pass through */
  transform-origin: center center;
  transform: rotate(0deg) scale(0.8); /* Initial transformation */
  transition: all 1s ease-out; /* Transition for smooth rotation */
  overflow: hidden!important;
}

.overlay-image.rotated {
  transform: rotate(90deg) scale(1.5); /* Rotate and scale the image */
}

.selected-navbar-indicator{
  pointer-events: none;
}